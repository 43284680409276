<template>
</template>
<script>
    import { UserInfo } from '../components/User/UserInfo'
    export default {
        name: 'thirdPartyLogin',
        data() {
            var userInfo = new UserInfo(this.TokenClient, this.Services.Authorization)
            return {
                organizationName: this.$route.query.organizationName, //机构名称
                department:this.$route.query.department, //部门名称或编号
                userAccount: this.$route.query.userAccount, //用户工号或手机号
                passWord:this.$route.query.passWord, //密码
                userinfoDomain: userInfo,
            }
        },
        methods: {
            Login: function () {
                var _this = this;
                if (!this.passWord) this.passWord = '123456';
                var longinInfo = {
                    UserAccount: this.userAccount,
                    Department: this.department,
                    OrganizationName: this.organizationName,
                    GrantType: 'otherLogin_auth'
                }
                this.userinfoDomain.Login(longinInfo,function (data) {
                    _this.$router.push({
                        path: "/index",
                        query: {},
                    });
                },
                    function (error) {
                        _this.$message.error('登录失败！请关闭后重新打开。');
                    })
            },
        },
        mounted(){
            this.Login();
        }
    }
</script>